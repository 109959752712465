import ProjectsHome from '../../projects/ProjectsHome'

const AdminProjectEdit  = () => {

    return(
        <div>
            <ProjectsHome />
        </div>
    )
}

export default AdminProjectEdit